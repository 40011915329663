@import './variables.scss';

#root {
  height: 100%;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: $background-color;
  color: $text-color;
}
