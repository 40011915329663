@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/Google\ Sans.woff2');
}

@font-face {
  font-family: 'Google Sans Greek';
  src: url('./assets/fonts/Google\ Sans\ Greek.woff2');
}

@font-face {
  font-family: 'Archivo Black';
  src: url('./assets/fonts/ArchivoBlack-Regular.ttf');
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Google Sans', 'Google Sans Greek', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.d-none {
  display: none !important;
}