@import '../../variables.scss';

.Header {
  width: calc(100% - 20px - 20px);
  display: flex;
  justify-content: space-between;
  padding: 20px;

  .brand {

    .logo {
      color: $primary-color;
    }

    .name {
      margin-left: 5px;
      font-weight: bold;
      font-size: 22px;
      color: $primary-color;
    }
  }

  .menu {
    display: flex;
    align-items: center;

    .account {

      .btn-toggle-account-menu {
        outline: none;
        border: none;
        display: flex;
        align-items: center;
        padding: 7px 10px;
        border-radius: 20px;
        background-color: $component-background-color;
        color: $component-text-color;
        cursor: pointer;
        box-shadow: $component-box-shadow;

        span {
          margin-left: 5px;
          font-size: 16px;
        }
      }
    }

    .btn-settings {
      outline: none;
      border: none;
      width: 45px;
      height: 45px;
      margin-left: 20px;
      border-radius: 50%;
      background-color: $component-background-color;
      color: $component-text-color;
      cursor: pointer;
      box-shadow: $component-box-shadow;
      transition: transform 250ms;

      &:hover {
        transform: rotate(30deg);
      }
    }
  }

  .balance {
    position: fixed;
    bottom: 20px;
    left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background-color: $component-background-color;
    box-shadow: $component-box-shadow;
    color: $component-text-color;

    .label {
      font-size: 12px;
      font-weight: bold;
    }

    .value {
      margin-top: 3px;
      font-weight: bold;
    }
  }
}

@media screen and (max-width: 374px) {
  .Header {
    flex-direction: column;

    .menu {
      margin-top: 20px;
    }
  }
}
